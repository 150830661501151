<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="仓库">
					<c-select name="warehouse_id" options="WL_WAREHOUSE_LIST" ></c-select>
				</m-search-item>
				<m-search-item label="物料名称">
					<c-input name="product_name"></c-input>
				</m-search-item>
				<m-search-item label="物料编码">
					<c-input name="product_code"></c-input>
				</m-search-item>
				<m-search-item label="">
					<c-checkbox-group name="is_have" value="1">
						<c-checkbox value="1">只看有库存</c-checkbox>
					</c-checkbox-group>
				</m-search-item>
			</m-search>

			<c-table ref="table" height="grow">
				<c-table-column
					label="仓库"
					width="100"
					name="warehouse_name"
				>
				</c-table-column>
				<c-table-column
					label="物料名称"
					width="300"
					name="product_name"
				>
				</c-table-column>
				<c-table-column
					label="物料编号"
					width="150"
					name="product_code"
				>
				</c-table-column>
				<c-table-column
					label="库存"
					width="100"
					name="product_count"
				>
				</c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="100"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b__inventory_material_editstock') && data.id" @click="editStock(data)">修改库存</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
		
		<!--编辑库存 弹框-->
		<c-dialog ref="editStockDialog" :title="this.product_name+' - 修改库存'" width="400" @resolve="submitEditStock">
			<c-form ref="editStockForm" unit-width="100%">
				<c-input name="product_count" type="number" decimal="0"></c-input>
			</c-form>
		</c-dialog>
  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'
import {asyncMapState} from '@/store'

export default {
	name: 'm_inventory_material',
	components: {
    
	},
	data() {
		return {
			product_name: ''
		}
	},
	computed: {
		...asyncMapState(['DIC_INSTORAGE_SOURCE'])
	},
	mounted() {
		this.$refs.search.search();
		this.$bus.$on('productInventoryList.material.update', page => {
			this.$refs.table.update(page);
		});
	},
	methods: {
		//搜索
		searchHandle(data) {
			data.type = 2;
			this.$refs.table.load({
				url: '/inventory/material_list',
				data
			});
		},
		//修改库存 - 弹框
		editStock(val) {
			this.stock_id = val.id;
			this.product_id = val.product_id;
			this.product_name = val.product_name;
			this.product_code = val.product_code;
			this.$refs.editStockForm.set(val);
			this.$refs.editStockDialog.open();
		},
		//修改库存 - 提交
		submitEditStock(stop) {
			stop();
			this.$refs.editStockForm.submit({
				url: '/inventory/material_edit_stock',
				rule: {
					product_count: '请填写最新库存'
				},
				dataFormatter: data => {
					data.product_name = this.product_name;
					data.product_code = this.product_code;
					data.stock_id = this.stock_id;
					data.product_id = this.product_id;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '修改库存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.editStockDialog.close();
				}
			});
		},
  }
}
</script>